import apiaxios from "../../common/apiaxios";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import css from "./ProductFavoriteCompare.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserCompareCount,
  changeUserFavoriteCount,
} from "../../redux/userSlice";

const ProductFavoriteCompare = ({
  type,
  id,
  favoriteStatus,
  compareStatus,
}) => {
  const userStatus = useSelector((state) => state.user.status);
  const dispatch = useDispatch();
  const [favorite, setFavorite] = useState(favoriteStatus);
  const [compare, setCompare] = useState(compareStatus);

  const favoriteClick = () => {
    if (userStatus === "guest") {
      toast.error("Доступно тільки зареєстрованим користувачам!");
    } else {
      apiaxios.post("/users/post_product_favorite?id=" + id).then((res) => {
        dispatch(changeUserFavoriteCount(res.data["favoriteCount"]));
        setFavorite(!favorite);
      });
    }
  };

  const compareClick = () => {
    if (userStatus === "guest") {
      toast.error("Доступно тільки зареєстрованим користувачам!");
    } else {
      apiaxios.post("/users/post_product_compare?id=" + id).then((res) => {
        dispatch(changeUserCompareCount(res.data["compareCount"]));
        setCompare(!compare);
      });
    }
  };

  const removeClick = () => {
    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm("Ви впевнені, що хочете видалити картку?");
    if (isConfirmed) {
      apiaxios.post("/catalog/remove_product?id=" + id).then((res) => {
        if (res.data["result"] === "card deleted!") {
          window.location.reload();
        } else {
          alert("Помилка: не вдалося видалити - картка буда задіяна!");
        }
      });
    }
  };

  return (
    <>
      <div
        className={
          (type = "line"
            ? css["favorite-compare-item-line"]
            : css["favorite-compare-item"])
        }
        onClick={favoriteClick}
      >
        <div
          className={
            favorite
              ? css["favorite-compare-item-favorite"]
              : css["favorite-compare-item-not-favorite"]
          }
        >
          <svg
            width={window.innerWidth >= 500 ? "18px" : "15px"}
            height={window.innerWidth >= 500 ? "18px" : "15px"}
          >
            <use xlinkHref="#svg-icon__heart" />
          </svg>
        </div>
      </div>
      <div
        className={
          (type = "line"
            ? css["favorite-compare-item-line"]
            : css["favorite-compare-item"])
        }
        onClick={compareClick}
      >
        <div
          className={
            compare
              ? css["favorite-compare-item-compare"]
              : css["favorite-compare-item-not-compare"]
          }
        >
          <svg
            width={window.innerWidth >= 500 ? "20px" : "17px"}
            height={window.innerWidth >= 500 ? "20px" : "17px"}
          >
            <use xlinkHref="#svg-icon__libra" />
          </svg>
        </div>
      </div>
      {userStatus === "super" && (
        <div
          className={
            (type = "line"
              ? css["favorite-compare-item-line"]
              : css["favorite-compare-item"])
          }
          onClick={removeClick}
        >
          <div className={css["item-remove"]}>
            <svg
              width={window.innerWidth >= 500 ? "20px" : "17px"}
              height={window.innerWidth >= 500 ? "20px" : "17px"}
            >
              <use xlinkHref="#svg-icon__remove" />
            </svg>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default ProductFavoriteCompare;
