import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import css from "./PortalMenu.module.css";
import apiaxios from "../../common/apiaxios";

const PortalMenu = () => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [surname, setSurname] = useState("");

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      const path = window.location.href;
      apiaxios
        .get("/users/get_user_info/")
        .then((res) => {
          setFirstname(res.data.first_name);
          setSurname(res.data.last_name);
          setEmail(res.data.email);
        })
        .catch((err) => {
          console.log(err);
          window.location.href = path;
        });
    }
  }, []);

  return (
    <>
      <div className={css["sidebar-header"]}>
        <div className={css["sidebar-header-name"]}>
          {firstname} {surname}
        </div>
        <div className={css["sidebar-header-email"]}>{email}</div>
      </div>
      <ul className={css["sidebar-block"]}>
        <li className={css["sidebar-item"]}>
          <Link to="orders_list" className={css["profile-menu-item"]}>
            <div>Журнал замовлень</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link to="sources_list" className={css["profile-menu-item"]}>
            <div>Постачальники та склади</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link
            to="vendors_categories_list"
            className={css["profile-menu-item"]}
          >
            <div>Категорії товару постачальників</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link
            to="products_without_category/0000"
            className={css["profile-menu-item"]}
          >
            <div>Картки товару без категорій</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link to="product_dublicate" className={css["profile-menu-item"]}>
            <div>Картки товару з дублюванням цін одного постачальника</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link to="clients_dictionary" className={css["profile-menu-item"]}>
            <div>Довідник контрагентів</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link to="users_dictionary" className={css["profile-menu-item"]}>
            <div>Довідник користувачів</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link to="staff_dictionary" className={css["profile-menu-item"]}>
            <div>Довідник співробітників</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link to="firms_dictionary" className={css["profile-menu-item"]}>
            <div>Довідник фірми</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link to="accounts_dictionary" className={css["profile-menu-item"]}>
            <div>Довідник розрахункові рахунки та каси</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link to="search_product_info" className={css["profile-menu-item"]}>
            <div>Пошук товару за артикулом чи кодом</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link
            to="create_product_card_manualy"
            className={css["profile-menu-item"]}
          >
            <div>Створення картки товару вручну</div>
          </Link>
        </li>
        <li className={css["sidebar-item"]}>
          <Link to="get_pdv_inventory" className={css["profile-menu-item"]}>
            <div>Аналіз залишків товару і залишків з ПДВ</div>
          </Link>
        </li>
      </ul>
    </>
  );
};

export default PortalMenu;
