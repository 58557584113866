import { useEffect, useState } from "react";
// import css from "./PDVInventory.module.css";
import { Circles } from "react-loader-spinner";
import apiaxios from "../../common/apiaxios";

const PDVInventory = () => {
  const [data, setData] = useState([]);
  const [circle, setCircle] = useState(true);

  useEffect(() => {
    apiaxios
      .get("/catalog/get_pdv_inventory/")
      .then((res) => {
        setData(res.data["data"]);
        setCircle(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      {circle && (
        <div className="spinner">
          <Circles
            height="150"
            width="150"
            color="rgb(50, 130, 250)"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!circle && data && (
        <div style={{ marginTop: "10px" }}>
          {data.map((prod) => (
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "15px", width: "100px" }}>
                {prod["code"]}
              </div>
              <div style={{ marginRight: "15px", width: "600px" }}>
                {prod["name"]}
              </div>
              <div style={{ marginRight: "15px", width: "50px" }}>
                {prod["q_pdv"]}
              </div>
              <div style={{ marginRight: "15px", width: "50px" }}>
                {prod["q_fact"]}
              </div>
              <div style={{ marginRight: "15px", width: "80px" }}>
                {prod["price"]}
              </div>
              <div style={{ marginRight: "15px", width: "80px" }}>
                {prod["price_z_pdv"]}
              </div>
              <div style={{ marginRight: "15px", width: "80px" }}>
                {prod["price_out"]}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PDVInventory;
